import { useMemo } from 'react'
import { Formik } from 'formik'
import * as yup from 'yup'

import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'

import Screen from '../../components/layout/Screen'
import Header from '../../components/layout/Header'
import Main from '../../components/layout/Main'

import CaptionedLogo from '../../components/basic/CaptionedLogo'
import BackButton from '../../components/basic/BackButton'

import { VerificationCodeActions } from '../../redux/reducers/verificationCodeSlice'
import { ARABIC_ENGLISH_MOBILE_NUMBER_REGEX, MOBILE_NUMBER_REGEX } from '../../components/common'
import { checkExistingApi } from '../../redux/api/authApi'
import { getUserType } from '../../redux/api/util'

export const Hash = 'verifyMobileNumber'

export const Component = ({ isLoading, dispatch, updateForm, failed, captions = {}, header, underButton, target }) => {
  const handleSubmit = ({ mobileNumber }) => {
    dispatch(VerificationCodeActions.createRequest({ mobileNumber, target }))
    updateForm('mobileNumber', mobileNumber)
  }


const schema = useMemo(() => {
  return yup.object().shape({
    mobileNumber: yup.string()
      .matches(ARABIC_ENGLISH_MOBILE_NUMBER_REGEX, 'من فضلك أدخل رقم هاتف صحيح')
      .required('من فضلك قم بادخال المعلومات كاملة')
      .typeError('من فضلك أدخل رقم الهاتف')
      .test('unique-phone', target === 'Signup' ? 'رقم الهاتف موجود بالفعل' : 'رقم الهاتف غير موجود', async (value) => {
        if (value) {
          const response = await checkExistingApi({ target: 'mobileNumber', userType: getUserType(), value })
          return target === 'Signup' ? !response.data : response.data
        }
        return true
      })
  })
}, [target])

  return (
    <Screen>
      <Header
        middleItem={<b>{header}</b>}
        rightItem={<BackButton />}
      />
      <Main>
        <CaptionedLogo caption={captions.caption} subCaption={captions.subCaption} small={captions.small} />
        <Formik
          validationSchema={schema}
          onSubmit={handleSubmit}
          initialValues={{
            mobileNumber: null
          }}
        >
          {({
            handleSubmit,
            handleChange,
            errors
          }) => (
            <Form className='mobile-verification-form' noValidate onSubmit={handleSubmit}>
              <Form.Group>
                <Form.Label>رقم الهاتف</Form.Label>
                <Form.Control
                  type='text'
                  placeholder='ادخل رقم الهاتف'
                  name='mobileNumber'
                  onChange={handleChange}
                  isInvalid={errors.mobileNumber || failed}
                />
                <Form.Control.Feedback type='invalid'>
                  {errors.mobileNumber || 'حدث خطأ ما. حاول مرّة أخرى'}
                </Form.Control.Feedback>
              </Form.Group>

              {/* id here is important as it links the button to the reCaptcha verifier when REACT_APP_VERIFICATION_AUTHORITY is `firebase`*/}
              <Button id='verify-phone' type='submit' className='login-form-submit' disabled={isLoading || errors.mobileNumber}>
                {isLoading ? <Spinner animation="border" size='sm' /> : <span>التحقق من رقم الهاتف</span>}
              </Button>
            </Form>
          )}
        </Formik>
        {underButton}
      </Main>
    </Screen>
  )
}
